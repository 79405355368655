import {Input, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {MarkdownModule} from 'ngx-markdown';
import {UserTrackingService} from '@angular/fire/analytics';
import {firebase, firebaseui, FirebaseUIModule} from 'firebaseui-angular';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  tosUrl: '/docs/terms',
  privacyPolicyUrl: 'docs/privacy',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
    imports: [
        BrowserModule,
        BrowserModule,
        AgmCoreModule.forRoot({
            apiKey: environment.gapiKey
        }),
        AngularFireModule.initializeApp(environment.firebase),
      FirebaseUIModule.forRoot(firebaseUiAuthConfig),
        IonicModule.forRoot(),
        MarkdownModule.forRoot({ loader: HttpClient }),
        AppRoutingModule,
        HttpClientModule,
    ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    UserTrackingService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
