import {Injectable, NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  PreloadAllModules,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  UrlTree
} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {first} from 'rxjs/operators';
import {AlertController} from '@ionic/angular';

@Injectable()
class AuthGuard implements CanActivate {
  private guestViewLimit = 5;
  constructor(private auth: AngularFireAuth, private alertController: AlertController, private router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean|UrlTree> {
    const session = await this.auth.user.pipe(first()).toPromise();
    if (! session) {
      let views = + sessionStorage.getItem('bw-views');
      views++;
      sessionStorage.setItem('bw-views', views + '');
      if (views > this.guestViewLimit) {
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Please sign in',
          message: 'to unlock member only features on BlueWaterFish...',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                return this.router.navigateByUrl('/');
              }
            }, {
              text: 'Okay',
              handler: () => {
                return this.router.navigateByUrl('/profile');
              }
            }
          ]
        });
        await alert.present();
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'docs',
    loadChildren: () => import('./pages/docs/docs.module').then( m => m.DocsPageModule)
  },
  {
    path: 'app',
    children: [
      {
        path: 'feed',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/feed/feed.module').then( m => m.FeedPageModule)
      },
      {
        path: 'pages',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/pages/pages.module').then( m => m.PagesPageModule)
      },
    ]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }

