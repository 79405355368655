import { Component } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private afs: AngularFirestore) {
  }
}
